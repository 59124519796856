import Container from './Container';
import images from '../../images/homepage';
import { defaultButtonText } from '../RequestADemoModal/constants';
import { REQUEST_DEMO_ANCHOR } from '../../core/links';
import * as React from 'react';

export const InterestedInStackHawkInflight = (): JSX.Element => (
  <Container
    image={images.StackHawkInFlight}
    button1Text={defaultButtonText}
    button1Link={REQUEST_DEMO_ANCHOR}
    variant="wide5050"
    heading="Want to know how StackHawk can improve your API Security and AppSec Programs?"
    description="Schedule time with our experts for a live demo."
    cssClass="mobileHideImg"
  />
);

export const BeHawksome = (): JSX.Element => (
  <Container
    heading="Get Hands-On Experience <br/> <span>Give Us A Test Drive!</span>"
    description="We know you might want to test drive a full version of security software before you talk to us. So, Get It On!"
    button1Text="Start Your Free Trial"
    button1Link="https://auth.stackhawk.com/signup"
    cssClass="hawksomeCard"
  />
);
