import React from 'react';
import { GetDynamicGenericComponent } from '../../../utils/GetDynamicGenericComponent';

export interface Props {
  component: string;
}

const GenericComponent: React.FC<Props> = (data): JSX.Element => (
  <> {GetDynamicGenericComponent(data.component, data)} </>
);

export default GenericComponent;
