import React from 'react';
import * as areaStyles from './style.module.scss';
import { GetDynamicContentfulComponent } from '../../../utils/GetDynamicContentfulComponent';

export interface Props {
  contentBlocks: Record<string, any>;
  backgroundStyle: string;
  pageType: string;
}

const styleOptions: {
  [key: string]: string;
} = {
  Default: '',
  'Gradient Background with Top Fade': areaStyles.bgGradientTopFade,
  'Long Gradient Background with Top Fade': areaStyles.bgGradientTopFadeLong,
};

const Area: React.FC<Props> = ({ contentBlocks, backgroundStyle, pageType }): JSX.Element => (
  <div className={`${areaStyles.areaWrapper} ${styleOptions[backgroundStyle]}`}>
    {contentBlocks.map((item: any): JSX.Element => GetDynamicContentfulComponent(item, pageType))}
  </div>
);

export default Area;
