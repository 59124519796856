import React from 'react';
import './style.scss';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import BulletPoint from '../BulletPoint';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { getClassNameString } from '../../../utils/FindStyles';
import { PillType } from '../../../types';

const HORIZONTAL_LAYOUT_BULLET_SUBTITLE = 'horizontalLayoutBulletSubtitle';

const styleOptions: {
  [key: string]: string;
} = {
  'Vertical layout': 'verticalLayout',
  'Horizontal layout with bullet subtitle': HORIZONTAL_LAYOUT_BULLET_SUBTITLE,
  'Padding 26': 'p26',
  'Padding horizontal 26': 'py26',
};

export interface Props {
  file: any;
  alternativeText: string;
  pageType?: string;
  title?: string;
  subtitle?: any;
  styles?: string[];
  imageCTA?: any;
}

export const Image: React.FC<Props> = ({
  file,
  alternativeText,
  title,
  subtitle,
  styles,
  pageType,
  imageCTA,
}): JSX.Element => {
  const formattedSubtitle = renderRichText(subtitle);
  const imageData: IGatsbyImageData = file.gatsbyImageData;
  const baseStyles = `${getClassNameString(styles, styleOptions)}`;
  const hasBulletPoint = baseStyles.includes(HORIZONTAL_LAYOUT_BULLET_SUBTITLE);

  return (
    <div className="imageComponent">
      <div className={`${baseStyles}`}>
        <GatsbyImage objectFit="contain" image={imageData} alt={alternativeText} />
        <div className="contentWrapper">
          <div className="title">{title}</div>
          {hasBulletPoint ? <BulletPoint text={formattedSubtitle} type={pageType as PillType} /> : formattedSubtitle}
        </div>
        {!!imageCTA && (
          <a
            className={imageCTA.styles ? imageCTA.styles.toLowerCase() : 'secondary'}
            href={imageCTA.url}
            target={imageCTA.target}
          >
            {imageCTA.label}
          </a>
        )}
      </div>
    </div>
  );
};

export default Image;
