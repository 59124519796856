import React from 'react';

export const title = 'Word on the street';

export interface IQuote {
  content: React.ReactNode;
  name?: string;
  title?: string;
}

export const defaultQuotes: IQuote[] = [
  {
    content: (
      <>
        StackHawk accelerated our acceptance into the Salesforce AppExchange by allowing us to easily find and mitigate
        even the smallest of security vulnerabilities. It continues to fortify the defenses of our platform on every
        commit so we can be proactive against future threats.
      </>
    ),
    name: 'Jacob Caban-Tomski',
    title: 'Sr. Software Engineer | Commercial Tribe',
  },
  {
    content: (
      <>
        We're constantly seeking opportunities for improving our security posture and StackHawk struck us immediately as
        a strong tool to include in our toolbox. Super pleased in running our first scans today, with time from
        registration to results and a periodic scan in place through GitHub Actions in twenty minutes.
      </>
    ),
    name: 'James Ramirez',
    title: 'CTO | Essentia Analytics',
  },
  {
    content: (
      <>
        Having used other tools to do application scanning, I am excited to watch Stackhawk democratize the process,
        making scan setup and execution easier for devs, QA, and DevOps folks.
      </>
    ),
    name: 'Tate Crumbley',
    title: 'Principal Security Engineer | Sovrn',
  },
];

// test
export const quotesCarouselTestId = 'quote-carousel';
