import React, { ChangeEvent, Dispatch, FormEvent, SetStateAction, useState } from 'react';
import * as formStyles from './style.module.scss';
import parse from 'html-react-parser';
import Modal from '../Modal';
import useSubscriptionForm, { COOKIE_CODE } from '../../../hooks/useSubscriptionForm';
import Button, { CallToAction } from '../Button';
import { AnimatePresence } from 'framer-motion';
import { API_SUBSCRIBE_ENDPOINT } from '../../../core/links';
import { useLocation } from '@gatsbyjs/reach-router';
import { navigate } from 'gatsby';
import * as mediaStyles from '../Media/style.module.scss';

enum FORM {
  SIMPLE = 'Simple',
  ON_RIGHT = 'Form on right',
}

export interface Props {
  componentForm: any;
  componentVariant: FORM;
  title: string;
  image?: any;
  componentCTA?: any;
  metadata?: any;
  childContentfulContentComponentBlockDescriptionTextNode?: any;
}

export interface FormProps {
  form: any;
  submissionUrl: string;
  submissionUrlToken: COOKIE_CODE;
  formId: string;
  slackChannelId: string;
  internalTitle: string;
  subscribe: string[];
  onShowModal: any;
  successModal: string;
  successModalTitle: string;
  successModalDescription: string;
  successModalImage: string;
  redirectSlug: string;
}

interface FieldItem {
  fieldId: string;
  label: string;
  name: string;
  isRequired: boolean;
  isEmail: boolean;
}

export interface FormEntry {
  [key: string]: string;
}

const Input = ({
  field,
  data,
  index,
  setData,
}: {
  field: FieldItem;
  data: FormEntry;
  index: number;
  setData: Dispatch<SetStateAction<FormEntry>>;
}): JSX.Element => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData((prevState: FormEntry) => {
      return {
        ...prevState,
        [e.target.id]: e.target.value,
      };
    });
  };

  return (
    <input
      id={field.name}
      value={data[field.name] || ''}
      onChange={handleChange}
      className={`form-control ${formStyles.input}`}
      placeholder={`${field.label} ${field.isRequired ? '*' : ''}`}
      autoFocus={index === 0}
      aria-label={field.label}
      required={field.isRequired}
      type={field.isEmail ? 'email' : 'text'}
    />
  );
};

const FormLayout: React.FC<FormProps> = ({
  form,
  submissionUrl,
  formId,
  slackChannelId,
  internalTitle,
  submissionUrlToken,
  onShowModal,
  subscribe,
  redirectSlug,
}) => {
  const [data, setData] = useState({} as FormEntry);
  const formData = form?.fieldData || {};
  const formFields = form?.fieldData?.data || {};

  const location = useLocation();

  const { submitData } = useSubscriptionForm(
    form,
    API_SUBSCRIBE_ENDPOINT,
    submissionUrl,
    formId,
    submissionUrlToken,
    slackChannelId,
    subscribe,
    internalTitle,
    location.href
  );

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onShowModal();
    submitData(data);
    setData({} as FormEntry);

    if (!!redirectSlug) {
      navigate(redirectSlug);
    }
  };

  return (
    <div>
      {!!formData && (
        <form className={formStyles.form} onSubmit={onSubmit}>
          <div className={`${formStyles.header} mb-3`}>{form?.title}</div>
          {formFields.map((field: FieldItem, index: number) => (
            <div key={field?.fieldId} className="mb-3">
              <Input field={field} data={data} index={index} setData={setData} />
            </div>
          ))}
          <p className={formStyles.hint}>{form?.footer}</p>
          <Button
            type="submit"
            variant="button-simple"
            label={form?.buttonLabel}
            style="btn-primary"
            disabled={false}
            size="btn"
          />
        </form>
      )}
    </div>
  );
};

const ComponentCallToAction = ({ componentCTA }: { componentCTA: any }): JSX.Element => (
  <div className={mediaStyles.mediaCallToAction}>
    {componentCTA.map((props: CallToAction, idx: number) => (
      <Button key={idx} {...props} />
    ))}
  </div>
);

const Form: React.FC<Props> = ({
  componentVariant,
  title,
  componentForm,
  componentCTA,
  childContentfulContentComponentBlockDescriptionTextNode,
}) => {
  const [showModal, setShowModal] = useState(false);
  const noop = () => {
    // do nothing.
  };
  return (
    <div
      className={`${formStyles.layout} 
        ${componentVariant === FORM.ON_RIGHT ? '' : formStyles.formLeftDirection}`}
    >
      <div className={formStyles.contentWrapper}>
        {!!title && <div className={formStyles.pb16}>{parse(title)}</div>}
        <div>
          {!!childContentfulContentComponentBlockDescriptionTextNode?.description &&
            parse(childContentfulContentComponentBlockDescriptionTextNode.description)}
        </div>
        {!!componentCTA && <ComponentCallToAction componentCTA={componentCTA} />}
      </div>

      <FormLayout
        {...componentForm}
        onShowModal={componentForm?.successModal === 'None' ? noop : () => setShowModal(true)}
      />
      <AnimatePresence>
        {showModal && (
          <Modal
            title={componentForm?.successModalTitle}
            description={componentForm?.successModalDescription}
            image={componentForm?.successModalImage}
            onHideModal={() => setShowModal(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Form;
