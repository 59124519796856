import { nanoid } from 'nanoid';
import React from 'react';
import { DynamicComponent } from '../../types';

export const GetDynamicComponent = ({
  component,
  componentList,
  props,
  block,
  pageType,
}: {
  component: string;
  componentList: any;
  props?: any;
  block?: any;
  pageType?: string;
}): JSX.Element => {
  if (component !== null) {
    const DynamicComponent: DynamicComponent = componentList[component];

    if (DynamicComponent !== undefined) {
      return <DynamicComponent key={nanoid()} {...props} {...block} pageType={pageType} />;
    }
  }

  return <div data-id="dynamic-component-error"></div>;
};
