import React from 'react';
import * as testimonialStyles from './style.module.scss';
import LeftQuote from '../../../images/left-quote.svg';
import RightQuote from '../../../images/right-quote.svg';
import parse from 'html-react-parser';

const applyTextGradientHighlight = (title: any): any => {
  const evilContentfulValue = 'style="color: rgb(255, 16, 240);"';

  if (title.indexOf(evilContentfulValue)) {
    title = title.replace(evilContentfulValue, 'class="hawk-rb-highlight"');
  }

  return title;
};

interface HeaderProps {
  childContentfulContentComponentBlockDescriptionTextNode: any;
  title?: string;
  childContentfulContentComponentBlockFooterTextNode: any;
}

const Testimonial: React.FC<HeaderProps> = ({
  childContentfulContentComponentBlockDescriptionTextNode,
  title,
  childContentfulContentComponentBlockFooterTextNode,
}): JSX.Element => (
  <div className={testimonialStyles.wrapper}>
    {!!title && <div className={testimonialStyles.title}>{parse(applyTextGradientHighlight(title))}</div>}
    <div className={testimonialStyles.left}>
      <img src={LeftQuote} alt="Left Quote" />
    </div>
    <div className={testimonialStyles.content}>
      {childContentfulContentComponentBlockDescriptionTextNode?.description?.replace(/(<([^>]+)>)/gi, '')}
      <div className={testimonialStyles.right}>
        <img src={RightQuote} alt="Right Quote" />
      </div>
    </div>
    {!!childContentfulContentComponentBlockFooterTextNode?.footer && (
      <div className={testimonialStyles.footer}>
        {parse(childContentfulContentComponentBlockFooterTextNode?.footer)}
      </div>
    )}
  </div>
);

export default Testimonial;
