import React from 'react';
import { PillType } from '../../../types';
import * as bulletPointStyles from './style.module.scss';

export interface Props {
  text: any;
  type: PillType;
}

export const BulletPoint: React.FC<Props> = ({ text, type }) => (
  <div className={bulletPointStyles.bulletPointWrapper}>
    <div className={`${bulletPointStyles.point} ${type.toLowerCase()}`}></div>
    <div className={bulletPointStyles.content}>{text} </div>
  </div>
);

export default BulletPoint;
