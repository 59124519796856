import React from 'react';
import * as pillStyles from './style.module.scss';
import { PillType } from '../../../types';

export interface PillProps {
  text: string;
  color?: string;
  type: PillType;
  pricingText?: string;
}

const Pill: React.FC<PillProps> = ({ text, type, color, pricingText }) => {
  return (
    <div
      style={!!color ? { color: color, border: `1px solid ${color}` } : {}}
      className={`${pillStyles.badgeWrapper} ${!!color ? pillStyles[type.toLowerCase()] : ''}`}
    >
      {text} <div>{pricingText}</div>
    </div>
  );
};

export default Pill;
