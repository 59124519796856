import { idCode, IFormSubscription, sentryHandleErr } from '../core/subscription';
import { FormEntry } from '../components/_POC/Form';

interface Data {
  name: string;
  label: string;
  fieldId: string;
  isRequired: boolean;
}

interface FieldData {
  data: Data[];
}

interface Form {
  buttonLabel: string;
  fieldData: FieldData;
  title: string;
  footer: string;
}

const COOKIE = {
  HUBSPOT: {
    REGEX: '(^|;)\\s*hubspotutk\\s*=\\s*([^;]+)',
  },
};

export type COOKIE_CODE = 'HUBSPOT';

const getCookie = (regex: string): string => document.cookie.match(regex)?.pop() || '';

enum HTTP_REQUEST_METHOD {
  POST = 'POST',
}

const useSubscriptionForm = (
  form: Form,
  apiUrl: string,
  url: string,
  formId: string,
  cookieKey: COOKIE_CODE,
  slackChannelId: string,
  subscribe: string[],
  internalTitle: string,
  userUrl: string
): { submitData: (postData: FormEntry) => void } => {
  const submitData = (postData: FormEntry) => {
    const pageUri = userUrl;
    const pageName = document.title;
    const hutk = getCookie(COOKIE?.[cookieKey]?.REGEX);
    const segmentAnonId = localStorage.getItem('ajs_anonymous_id')?.replace(/['"]+/g, '');
    const segmentUserId = localStorage.getItem('ajs_user_id')?.replace(/['"]+/g, '');

    const body = {
      version: '2',
      formId,
      subscribe,
      slackChannelId,
      internalTitle,
      userUrl,
      segmentAnonId,
      segmentUserId,
      fields: form.fieldData.data.map((rec) => ({ name: rec.name, value: postData[rec.name] })),
      context: {
        pageUri,
        pageName,
        hutk,
        ipAddress: '',
      },
    };

    // analytics tracking
    idCode({
      first_name: postData['firstname'] || '',
      last_name: postData['lastname'] || '',
      company: postData['company'] || '',
      email: postData['email'] || '',
      requestInfo: '',
      ml_company_info: '',
      subscribe: [formId],
      page: pageUri,
      hutk: hutk,
      source: '',
      start_time: '',
      country: postData['country'] || '',
      job_title: postData['jobtitle'] || '',
    } as IFormSubscription);

    const bodyString = JSON.stringify(body);

    fetch(apiUrl, {
      method: HTTP_REQUEST_METHOD.POST,
      headers: {
        'content-type': 'application/json',
      },
      body: bodyString,
    }).catch(sentryHandleErr(apiUrl, bodyString));
  };

  return { submitData };
};

export default useSubscriptionForm;
