import React from 'react';
import parse from 'html-react-parser';
import * as sectionStyles from './style.module.scss';
import { GetDynamicContentfulComponent } from '../../../utils/GetDynamicContentfulComponent';
import { getClassNameString } from '../../../utils/FindStyles';
import Pill from '../Pill';
import { PillType } from '../../../types';
import { GatsbyImage } from 'gatsby-plugin-image';
import CallToActionButton, { CallToAction } from '../Button';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { richTextRenderOptions } from 'richTextEditorOptions';
import { Carousel } from 'react-bootstrap';
import Container from '../Container/';
import { globalHistory } from '@reach/router';
import { REQUEST_DEMO_ANCHOR } from 'core/links';
import { defaultButtonText } from 'components/RequestADemoModal/constants';
import images from '../../../images/homepage/index';
import { BeHawksome, InterestedInStackHawkInflight } from '../HackShortTermComponents';

const pathname = globalHistory.location.pathname;

export enum LEADING_SUBTITLE_TYPE {
  TEXT = 'Text',
  PILL = 'Pill',
  BRAND = 'Brand',
}

enum CONTENT_SECTION_TYPE {
  DEFAULT = 'Default',
  HERO = 'Hero',
  CONTENT = 'Content',
  STATS_LEFT = 'Stats Left',
  CAROUSEL = 'Carousel',
}

enum IMAGE_LOCATION {
  ABOVE = 'Above Content',
  BELOW = 'Below Content',
}

enum VIDEO_LOCATION {
  ABOVE = 'Above Content',
  BELOW = 'Below Content',
}

export interface Props {
  contentSectionType: CONTENT_SECTION_TYPE;
  leadingSubtitle?: string;
  imageLocation: string;
  imageStyles?: string;
  trailingSubtitle: string;
  content: any;
  styles: string[];
  pageType: string;
  sectionCTA: any;
  sectionCTAAbove: any;
  image: any;
  video: any;
  videoLocation: any;
  metadata: any;
  contentGridColumns: number;
  contentGridColumnGap: number;
  contentGridRowGap: number;
  leadingSubtitleType: LEADING_SUBTITLE_TYPE;
  allLinksExternal: boolean;
  description: any;
  childContentfulContentSectionTitleTextNode: any;
  alignment: string;
  descriptionClassPadding: string;
  sectionClassPadding: string;
  pillColor?: string;
  cheatCodes?: string;
  subtitleAlignment?: 'left' | 'center' | 'right';
}

const styleOptions: {
  [key: string]: string;
} = {
  'Title size 2-XL': 'title-2-xl',
  'Title size 4-XL': 'title-4-xl',
  'Title size 6-XL': 'title-6-xl',
  'Gradient Background': 'bg-gradient',
  'Horizontal Layout': 'horizontal-layout',
  'Horizontal Layout Gap XXL': 'horizontal-layout-gap-xxl',
  'Grow Layout': 'grow-layout',
  'Hide on Mobile': 'hide-mobile',
  'Stretch Layout on Mobile': 'stretch-layout-mobile',
  'Basic Rounded Box 2-XXL': 'box-2-xxl',
  'Black Background': 'bg-black',
};

export const leadingSubtitleElement = (
  leadingSubtitle: string,
  leadingSubtitleType: LEADING_SUBTITLE_TYPE,
  subtitleAlignment?: 'left' | 'center' | 'right',
  pillColor?: string
): JSX.Element => {
  if (leadingSubtitleType === LEADING_SUBTITLE_TYPE.PILL) {
    return <Pill color={pillColor} text={leadingSubtitle.toUpperCase()} type={leadingSubtitle as PillType} />;
  }
  return (
    <p
      style={{ textAlign: subtitleAlignment || 'left' }}
      className={`${sectionStyles.sectionLeadingSubtitle}
        ${leadingSubtitleType === LEADING_SUBTITLE_TYPE.BRAND ? sectionStyles.textBrand400 : ''}
      `}
    >
      {leadingSubtitle}
    </p>
  );
};

const Section: React.FC<Props> = ({
  contentSectionType,
  leadingSubtitle,
  trailingSubtitle,
  content,
  styles,
  pageType,
  sectionCTA,
  sectionCTAAbove,
  image,
  imageLocation,
  imageStyles,
  video,
  videoLocation,
  leadingSubtitleType,
  allLinksExternal,
  contentGridColumns = 1,
  contentGridColumnGap = 1.5,
  contentGridRowGap = 3,
  description,
  childContentfulContentSectionTitleTextNode,
  alignment = 'Center',
  descriptionClassPadding = '',
  sectionClassPadding = '',
  pillColor,
  subtitleAlignment,
  cheatCodes,
}): JSX.Element => {
  const title = childContentfulContentSectionTitleTextNode?.title || '';

  const classNameString = getClassNameString(styles, styleOptions);

  const CallToAction = (props: CallToAction): JSX.Element => (
    <div className={sectionStyles.callToAction}>
      <CallToActionButton {...props} />
    </div>
  );

  const Image = ({ gatsbyImageData }: any): JSX.Element => (
    <div>
      <GatsbyImage image={gatsbyImageData} alt={'alternativeText'} />
    </div>
  );

  const contentObject = { content, contentGridColumns, contentGridRowGap, contentGridColumnGap };

  const Content = ({ content, contentGridColumns, contentGridRowGap, contentGridColumnGap }: any): JSX.Element => (
    <>
      {contentSectionType === CONTENT_SECTION_TYPE.CAROUSEL ? (
        <div className={'carouselSection'}>
          <Carousel controls={false}>
            {content?.map((item: any, idx: number) => (
              <Carousel.Item key={idx} interval={3000}>
                {GetDynamicContentfulComponent(item, pageType)}
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      ) : (
        <div
          className={`${sectionStyles.componentWrapper} ${
            content?.[0]?.['__typename'] === 'ContentfulTestimonialBlock' && 'mb-12'
          }`}
          style={{
            gridTemplateColumns: `repeat(${contentGridColumns}, 1fr)`,
            gridGap: `${contentGridRowGap}rem ${contentGridColumnGap}rem`,
          }}
        >
          {content?.map((item: any) => GetDynamicContentfulComponent(item, pageType))}
        </div>
      )}
      {content?.[0]?.['__typename'] === 'ContentfulTestimonialBlock' && pathname.includes('snyk') && (
        <>
          <Container
            image={images.StackHawkInFlight}
            button1Text={defaultButtonText}
            button1Link={REQUEST_DEMO_ANCHOR}
            variant="wide5050"
            heading="Want to know how StackHawk can improve your API Security and AppSec Programs?"
            description="Schedule time with our experts for a live demo."
            cssClass="mobileHideImg"
          />
          <Container
            heading="Get Hands-On Experience <br/> <span>Give Us A Test Drive!</span>"
            description="We know you might want to test drive a full version of security software before you talk to us. So, Get It On!"
            button1Text="Start Your Free Trial"
            button1Link="https://auth.stackhawk.com/signup"
            button2Text="Read the Docs"
            button2Link="https://docs.stackhawk.com/"
          />
        </>
      )}
    </>
  );

  const imageElement: JSX.Element = !!image?.gatsbyImageData ? (
    <div
      className={`${sectionStyles.textCenter}
        ${
          contentSectionType === CONTENT_SECTION_TYPE.CONTENT
            ? `${imageLocation === IMAGE_LOCATION.ABOVE ? '' : sectionStyles.pt88} ${sectionStyles.pb22}`
            : ''
        }
        ${!!imageStyles ? imageStyles : ''}
      `}
    >
      <Image gatsbyImageData={image.gatsbyImageData} />
    </div>
  ) : (
    <></>
  );

  const videoElement: JSX.Element = !!video ? (
    <div className={`container ${sectionStyles.ht_53}`}>
      <iframe
        src={video}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
        allowFullScreen
        height="100%"
        width="100%"
        className="border-0 embed-responsive-item"
      />
    </div>
  ) : (
    <></>
  );

  const InterestedInStackHawkInflightCode = 'Interested in seeing the StackHawk in flight';
  const BeHawksomeCode = "Don't wait to be Hawksome";
  const theCheatCodes = [InterestedInStackHawkInflightCode, BeHawksomeCode];

  const hasCheatCodes = cheatCodes && theCheatCodes.indexOf(cheatCodes) > -1;

  const cheatLookup = (
    <div className={`${sectionStyles.mb72}`}>
      {cheatCodes === InterestedInStackHawkInflightCode && (
        <div className="container">
          <InterestedInStackHawkInflight />
        </div>
      )}
      {cheatCodes === BeHawksomeCode && (
        <div className="container">
          <BeHawksome />
        </div>
      )}
    </div>
  );

  const oldSection = (
    <div
      className={`
      ${pathname.includes('snyk') && 'container'}
      ${sectionClassPadding}
      ${sectionStyles.sectionWrapper}
      ${
        content?.[0]?.['__typename'] === 'ContentfulContentComponentBlock' &&
        pathname.includes('snyk') &&
        classNameString === 'bg-black'
      }
      ${
        contentSectionType === CONTENT_SECTION_TYPE.STATS_LEFT
          ? `${sectionStyles.statDetailAlignment} ${sectionStyles.statsWrapper}`
          : ''
      }
    `}
    >
      {!!content && contentSectionType === CONTENT_SECTION_TYPE.STATS_LEFT && (
        <div className={`${sectionStyles.sectionContainer}`}>
          <Content {...contentObject} />
        </div>
      )}
      {videoLocation === VIDEO_LOCATION.ABOVE && videoElement}
      <div
        className={`${sectionStyles.sectionContainer} ${
          classNameString === 'bg-black' &&
          content?.[0]?.['__typename'] === 'ContentfulContentComponentBlock' &&
          `${sectionStyles.bgBlack} container`
        } ${classNameString}`}
      >
        <div
          className={`${descriptionClassPadding} ${sectionStyles[alignment]} ${sectionStyles.sectionTitleWrapper}
          ${contentSectionType === CONTENT_SECTION_TYPE.CONTENT ? sectionStyles.sectionContentType : ''}
        `}
        >
          {!!leadingSubtitle &&
            leadingSubtitleElement(leadingSubtitle, leadingSubtitleType, subtitleAlignment, pillColor)}
          {!!title && <div className={sectionStyles.title}>{parse(title)}</div>}
          {!!trailingSubtitle && <p className={sectionStyles.sectionTrailingSubtitle}>{trailingSubtitle}</p>}
          {imageLocation === IMAGE_LOCATION.ABOVE && imageElement}
          {!!description && <div>{renderRichText(description, richTextRenderOptions(allLinksExternal))}</div>}
        </div>
        {!!sectionCTAAbove && <CallToAction {...sectionCTAAbove} />}
        {imageLocation === IMAGE_LOCATION.BELOW && imageElement}
        {videoLocation === VIDEO_LOCATION.BELOW && videoElement}
        {!!content && contentSectionType !== CONTENT_SECTION_TYPE.STATS_LEFT && <Content {...contentObject} />}
        {!!sectionCTA && <CallToAction {...sectionCTA} />}
      </div>
    </div>
  );

  const sectionElement = hasCheatCodes ? cheatLookup : oldSection;

  return sectionElement;
};

export default Section;
