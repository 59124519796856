import React from 'react';
import PrimaryCard from './Primary';
import SimpleCard from './Simple';
import InfoCard from './Info';

export const CARD_STYLE_OPTIONS: {
  [key: string]: string;
} = {
  'Blank, Title Centered': 'blank-title-centered',
  Outline: 'outline',
  'Gradient, Outside CTA': 'infoWrapper',
  'Gradient, Expanded, CTA': 'primaryCardExpandedWrapper',
};

interface Props {
  componentVariant: string;
  title: string;
  componentVariantStyle: string;
  image?: any;
  url?: string;
  urlTitle?: string;
  metadata?: any;
  componentCTA?: any;
  childContentfulContentComponentBlockDescriptionTextNode?: any;
}

const Card: React.FC<Props> = ({
  componentVariant,
  image,
  url,
  urlTitle,
  title,
  metadata,
  childContentfulContentComponentBlockDescriptionTextNode,
  componentVariantStyle,
  componentCTA,
}) => {
  const SIMPLE_CARD = (
    <SimpleCard
      title={title}
      image={image}
      url={url}
      urlTitle={urlTitle}
      componentVariantStyle={componentVariantStyle}
      componentCTA={componentCTA}
      description={childContentfulContentComponentBlockDescriptionTextNode?.description}
    />
  );

  const CARDS: any = {
    Primary: <PrimaryCard url={url} image={image} componentVariantStyle={componentVariantStyle} title={title} />,
    Simple: SIMPLE_CARD,
    Media: SIMPLE_CARD,
    Info: (
      <InfoCard
        description={childContentfulContentComponentBlockDescriptionTextNode?.description}
        image={image}
        componentCTA={componentCTA}
        componentVariantStyle={componentVariantStyle}
      />
    ),
  };

  return <> {CARDS[componentVariant]} </>;
};

export default Card;
