import React from 'react';
import * as cardStyles from './style.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getClassNameString } from '../../../../utils/FindStyles';
import { CARD_STYLE_OPTIONS } from '../index';
import parse from 'html-react-parser';

interface Props {
  title: string;
  url?: string;
  image?: any;
  componentVariantStyle: string;
}

const PrimaryCard: React.FC<Props> = ({ title, image, componentVariantStyle, url }) => (
  <div
    onClick={() => {
      window.open(url, '_blank', 'noopener noreferrer');
    }}
    className={`${cardStyles.primary} ${cardStyles[getClassNameString([componentVariantStyle], CARD_STYLE_OPTIONS)]}`}
  >
    <div className={cardStyles.wrapper}>
      {image?.gatsbyImageData && (
        <GatsbyImage image={image?.gatsbyImageData} alt={'alternativeText'} objectFit="contain" />
      )}
      {!!title && <div className={cardStyles.title}>{parse(title)}</div>}
    </div>
  </div>
);

export default PrimaryCard;
