import React from 'react';
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { GatsbyImage } from "gatsby-plugin-image";
import Table from 'components/_POC/Table';

export const richTextRenderOptions = (allLinksExternal) => (
    {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: node => {
                return (
                    <div className='text-center py-4'>
                        <GatsbyImage className="my-2" image={node.data.target.gatsbyImageData} alt={`image`} />
                    </div>
                )
            },
            [BLOCKS.TABLE]: (node, children) => {
                return (<Table tableContent={children} />)
            },
            [INLINES.EMBEDDED_ENTRY]: (node) => {
                const { url } = node?.data?.target?.file;
                const { alternativeText, __typename } = node?.data?.target;
          
                if (__typename === 'ContentfulContentImageBlock') {
                  return <img alt={alternativeText} src={url} />;
                }
            },
            [INLINES.HYPERLINK]: (node, inputText) => {
                return (
                    <a href={node.data.uri} target={allLinksExternal ? "_blank noreferrer noopener" : "_self"}>{inputText}</a>
                )
            }
        }
    }
)