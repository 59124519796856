import React from 'react';
import Layout from '../layouts/default';
import { graphql } from 'gatsby';
import { GetDynamicContentfulComponent } from '../utils/GetDynamicContentfulComponent';
import SEO from '../components/seo';
import { globalHistory } from '@reach/router';
import Container from 'components/_POC/Container';
import images from '../images/homepage/index';
import { SIGNUP_URL, REQUEST_DEMO_ANCHOR } from 'core/links';
import { defaultButtonText } from 'components/RequestADemoModal/constants';

const ContentPageTemplate: React.FC = ({
  data: {
    contentfulContentPage: { blocks, type: pageType, seo },
  },
}: any): JSX.Element => {
  const pathname = globalHistory.location.pathname;

  return (
    <Layout>
      {!!seo && <SEO data={seo} />}
      <div className="content-page-wrapper mt-8 p-1">
        {!!blocks && blocks.map((block: any) => GetDynamicContentfulComponent(block, pageType))}
        {(pathname === '/github/' || pathname === '/github') && (
          <>
            <div className="container mb-8">
              <Container
                image={images.ApiCoverageImg}
                button1Text={defaultButtonText}
                button1Link={REQUEST_DEMO_ANCHOR}
                variant="wide5050"
                heading="Let’s not make<br/> this Hawkward!"
                description="Have more questions and would like a live demo? Reach out and one of our Hawks will be happy to guide you through our platform."
                cssClass="mobileHideImgDemo"
              />
            </div>
            <div className="container">
              <Container
                heading="Get Hands-On Experience <br/> <span>Give Us A Test Drive!</span>"
                description="We know you might want to test drive a full version of security software before you talk to us. So, Get It On!"
                button1Text="Start Your Free Trial"
                button1Link={SIGNUP_URL}
                button2Text="Read the Docs"
                button2Link="https://docs.stackhawk.com/workflow-integrations/github-app/"
                cssClass="hawksomeCard"
              />
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default ContentPageTemplate;

export const pageQuery = graphql`
  query ContentPageBySlug($slug: String!) {
    contentfulContentPage(slug: { eq: $slug }) {
      ...ContentfulContentPageFragment
    }
  }
`;
