import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IQuote, defaultQuotes, title as defaultTitle, quotesCarouselTestId } from './constants';
import { faQuoteLeft } from '@fortawesome/pro-duotone-svg-icons/faQuoteLeft';
import { faQuoteRight } from '@fortawesome/pro-duotone-svg-icons/faQuoteRight';
import { Carousel, CarouselItem } from 'react-bootstrap';

import './style.scss';

export interface Props {
  title?: string;
  backgroundColor?: string;
  quotes: IQuote[];
}

export const QuoteCarousel: React.FC<{ quotes: IQuote[] }> = ({ quotes }) => {
  return (
    <>
      <Carousel controls={false} data-testid={quotesCarouselTestId}>
        {quotes.map(({ name, title, content }, idx) => (
          <CarouselItem key={idx}>
            <div className="d-block">
              <div className="row">
                <div className="col-12 text-left">
                  <FontAwesomeIcon className="fz-21 d-block text-primary-light" icon={faQuoteLeft} />
                  <div className="fz-18 fz-lg-24 mb-3 d-flex flex-row">
                    <div>{content}</div>
                    <div className="mt-auto">
                      <FontAwesomeIcon className="ml-2 fz-16 text-primary-light" icon={faQuoteRight} />
                    </div>
                  </div>
                  {name && <p className="text-gray-300 fz-12 mb-0 font-weight-bold">{name}</p>}
                  {title && <p className="text-gray-300 fz-12 font-weight-normal mb-4">{title}</p>}
                </div>
              </div>
            </div>
          </CarouselItem>
        ))}
      </Carousel>
    </>
  );
};

export const Testimonials: React.FC<Props> = ({ title = defaultTitle, quotes = defaultQuotes, backgroundColor }) => {
  const background = backgroundColor ? backgroundColor : 'asphalt';
  return (
    <section className={`bg-${background} px-4 px-md-0 py-5 testimonials`} id="testimonials">
      <h3 className="mb-4  text-center ">{title}</h3>
      <div className="container">
        <QuoteCarousel quotes={quotes} />
      </div>
    </section>
  );
};

export default Testimonials;
