import React from 'react';
import { GetDynamicContentfulComponent } from '../../../utils/GetDynamicContentfulComponent';

export interface Props {
  content: any;
  pageType: string;
  contentGridColumns: number;
  contentGridColumnGap: number;
  contentGridRowGap: number;
}

const Subsection: React.FC<Props> = ({
  content,
  contentGridColumns,
  contentGridColumnGap,
  contentGridRowGap,
  pageType,
}): JSX.Element => (
  <div>
    {!!content && (
      <div
        style={{
          gridTemplateColumns: `repeat(${contentGridColumns}, 1fr)`,
          gridGap: `${contentGridRowGap}rem ${contentGridColumnGap}rem`,
        }}
      >
        {content?.map((item: any) => GetDynamicContentfulComponent(item, pageType))}
      </div>
    )}
  </div>
);

export default Subsection;
