import { ContentModelTypes } from '../../types';
import Image from '../../components/_POC/Image';
import Area from '../../components/_POC/Area';
import Section from '../../components/_POC/Section';
import Subsection from '../../components/_POC/Subsection';
import GenericComponent from '../../components/_POC/GenericComponent';
import { GetDynamicComponent } from '../GetDynamicComponent';
import Testimonials from 'components/Testimonials';

const CONTENTFUL_CONTENT_MODELS: {
  [key: string]: ContentModelTypes;
} = {
  ContentfulContentImageBlock: Image,
  ContentfulContentArea: Area,
  ContentfulContentSection: Section,
  ContentfulContentSubsection: Subsection,
  ContentfulContentComponentBlock: GenericComponent,
  ContentfulTestimonialBlock: Testimonials,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const GetDynamicContentfulComponent = (block: any, pageType: any): JSX.Element =>
  GetDynamicComponent({ component: block.__typename, componentList: CONTENTFUL_CONTENT_MODELS, pageType, block });
