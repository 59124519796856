import React, { useCallback, useEffect } from 'react';
import * as modalStyles from './style.module.scss';
import times from '../../../images/times.svg';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

export interface Props {
  title: string;
  description: any;
  image: any;
  onHideModal: any;
}

const OVERLAY_ID = 'modal-overlay';

const Modal: React.FC<Props> = ({ title, description, image, onHideModal }) => {
  const imageData: IGatsbyImageData = image.gatsbyImageData;

  useEffect(() => {
    document.body.classList.add(modalStyles.modalBkgHideOverflow);

    const element = document.createElement('div');
    element.className = modalStyles.modalBkg;
    element.id = OVERLAY_ID;
    document.body.appendChild(element);

    return () => {
      document.body.classList.remove(modalStyles.modalBkgHideOverflow);

      const element = document.getElementById(OVERLAY_ID);
      element?.remove();
    };
  }, []);

  const onEscKeydown = useCallback((event) => {
    if (event.key === 'Escape') {
      onHideModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onEscKeydown, false);

    return () => {
      document.removeEventListener('keydown', onEscKeydown, false);
    };
  }, [onEscKeydown]);

  return (
    <motion.div
      className={modalStyles.modal}
      role="dialog"
      aria-modal="true"
      aria-live="assertive"
      aria-labelledby="modal-alert"
      tabIndex={-1}
      initial={{ opacity: 0, y: 0, scale: 0.5 }}
      animate={{
        opacity: 1,
        y: 0,
        scale: 1,
        transition: { type: 'spring', stiffness: 200 },
      }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.3 } }}
    >
      <div className={modalStyles.times}>
        <a onClick={onHideModal} aria-label="Close">
          <img src={times} alt="Close Modal" />
        </a>
      </div>
      <div className={modalStyles.body}>
        <div className={modalStyles.content}>
          <div className={modalStyles.wrapper}>
            <h1>{title}</h1>
            {renderRichText(description)}
          </div>
        </div>
        <div className={modalStyles.imageContent}>
          <div className={modalStyles.wrapper}>
            <GatsbyImage image={imageData} alt={'todo'} />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Modal;
