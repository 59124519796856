import React from 'react';
import * as cardStyles from './style.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getClassNameString } from '../../../../utils/FindStyles';
import { CARD_STYLE_OPTIONS } from '../index';
import Button, { CallToAction } from '../../Button';
import parse from 'html-react-parser';

interface Props {
  image: any;
  description: any;
  componentCTA: any;
  componentVariantStyle: string;
}

const InfoCard: React.FC<Props> = ({ image, componentVariantStyle, description, componentCTA }) => (
  <div className={cardStyles.infoCard}>
    <div className={`${cardStyles[getClassNameString([componentVariantStyle], CARD_STYLE_OPTIONS)]}`}>
      <div className={cardStyles.content}>
        {image?.gatsbyImageData && (
          <div className={cardStyles.pb16}>
            <GatsbyImage image={image?.gatsbyImageData} alt={'alternativeText'} objectFit="contain" />
          </div>
        )}
        {!!description && <div className={cardStyles.description}>{parse(description)}</div>}
      </div>
    </div>
    <div className={`${cardStyles.pt45} w-100 text-center`}>
      {componentCTA?.map((props: CallToAction, idx: number) => (
        <Button key={idx} {...props} />
      ))}
    </div>
  </div>
);

export default InfoCard;
