import React from 'react';
import * as mediaStyles from './style.module.scss';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import Button, { CallToAction } from '../Button';
import { leadingSubtitleElement, LEADING_SUBTITLE_TYPE } from '../Section';

enum MEDIA {
  ON_RIGHT = 'Media on right',
  IMAGE_ON_RIGHT = 'Image on right',
  VID_ON_RIGHT = 'Video on right',
}

export interface Props {
  componentVariant: MEDIA;
  leadingSubtitleType: LEADING_SUBTITLE_TYPE;
  leadingSubtitle: string;
  title: string;
  trailingSubtitle: string;
  image?: any;
  videoUrl?: string;
  assetPadding?: string;
  subtitleAlignment?: 'left' | 'center' | 'right';
  assetAlignment?: 'left' | 'center' | 'right';
  componentCTA?: any;
  metadata?: any;
  childContentfulContentComponentBlockDescriptionTextNode?: any;
  isAnimated: boolean;
}

const MediaImage = ({
  gatsbyImageData,
  isAnimated,
}: {
  gatsbyImageData: IGatsbyImageData;
  isAnimated: boolean;
}): JSX.Element => {
  const image = <GatsbyImage image={gatsbyImageData} alt={'alternativeText'} objectFit="contain" />;

  return isAnimated ? (
    <div>
      <motion.div initial={{ y: 150 }} whileInView={{ y: 0 }} viewport={{ once: true }}>
        {image}
      </motion.div>
    </div>
  ) : (
    <div>{image}</div>
  );
};

export const MediaVideo = ({ videoUrl }: { videoUrl: string }): JSX.Element => (
  <div className="embed-responsive embed-responsive-16by9">
    <iframe
      src={videoUrl}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
      allowFullScreen
      height="100%"
      width="100%"
      className="border-0 embed-responsive-item"
    />
  </div>
);

const ComponentCallToAction = ({ componentCTA }: { componentCTA: any }): JSX.Element => (
  <div className={mediaStyles.mediaCallToAction}>
    {componentCTA.map((props: CallToAction, idx: number) => (
      <Button key={idx} {...props} />
    ))}
  </div>
);

const Media: React.FC<Props> = ({
  componentVariant,
  image,
  videoUrl,
  leadingSubtitle,
  leadingSubtitleType,
  title,
  trailingSubtitle,
  componentCTA,
  childContentfulContentComponentBlockDescriptionTextNode,
  subtitleAlignment,
  assetAlignment,
  assetPadding,
  isAnimated = false,
}) => (
  <div
    className={`${mediaStyles.layout} 
      ${
        componentVariant === MEDIA.ON_RIGHT || componentVariant === MEDIA.IMAGE_ON_RIGHT
          ? mediaStyles.mediaRightDirection
          : ''
      }
      ${componentVariant === MEDIA.VID_ON_RIGHT ? mediaStyles.vidRightDirection : ''}`}
  >
    <div className={assetPadding} style={{ textAlign: assetAlignment || 'left' }}>
      {image && <MediaImage gatsbyImageData={image && image.gatsbyImageData} isAnimated={isAnimated} />}
      {videoUrl && <MediaVideo videoUrl={videoUrl} />}
    </div>
    <div className={mediaStyles.contentWrapper}>
      {!!leadingSubtitle && leadingSubtitleElement(leadingSubtitle, leadingSubtitleType, subtitleAlignment)}
      {!!title && <div>{parse(title)}</div>}
      {!!trailingSubtitle && (
        <p style={{ textAlign: subtitleAlignment || 'left' }} className={mediaStyles.sectionTrailingSubtitle}>
          {trailingSubtitle}
        </p>
      )}
      <div>
        {!!childContentfulContentComponentBlockDescriptionTextNode?.description &&
          parse(childContentfulContentComponentBlockDescriptionTextNode?.description)}
      </div>
      {!!componentCTA && <ComponentCallToAction componentCTA={componentCTA} />}
    </div>
  </div>
);

export default Media;
