import { ComponentGenericTypes } from '../../types';
import { GetDynamicComponent } from '../GetDynamicComponent';
import Card from '../../components/_POC/Card';
import Media from '../../components/_POC/Media';
import Form from '../../components/_POC/Form';
import Testimonial from '../../components/_POC/Testimonial';

const COMPONENT_GENERIC_LIST: {
  [key: string]: ComponentGenericTypes;
} = {
  Card,
  Media,
  Form,
  Testimonial,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const GetDynamicGenericComponent = (component: string, props: any): JSX.Element =>
  GetDynamicComponent({ component, componentList: COMPONENT_GENERIC_LIST, props });
