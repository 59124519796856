import React from 'react';
import * as cardStyles from './style.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getClassNameString } from '../../../../utils/FindStyles';
import { CARD_STYLE_OPTIONS } from '../index';
import parse from 'html-react-parser';
import { navigate } from 'gatsby';
import Button, { CallToAction } from '../../Button';
import LongArrowLeft from '../../../../images/long-arrow-left.svg';

interface Props {
  title: string;
  image?: any;
  description?: any;
  componentVariantStyle: string;
  url?: string;
  urlTitle?: string;
  componentCTA: any;
}

const SimpleCard: React.FC<Props> = ({
  title,
  image,
  componentVariantStyle,
  description,
  url,
  urlTitle,
  componentCTA,
}) => {
  const onClickHandler = () => {
    if (!!url) {
      navigate(url);
    }
  };

  return (
    <div
      role={!!url ? 'button' : undefined}
      onClick={onClickHandler}
      className={`${cardStyles[getClassNameString([componentVariantStyle], CARD_STYLE_OPTIONS)]}`}
    >
      <div className={cardStyles.wrapper}>
        {image?.gatsbyImageData && (
          <GatsbyImage image={image?.gatsbyImageData} alt={'alternativeText'} objectFit="contain" />
        )}
        {!!title && <div>{parse(title)}</div>}
        {!!description && <div className={cardStyles.description}>{parse(description)}</div>}
        {!!componentCTA && (
          <div className={`pt-2 w-100 text-center`}>
            {componentCTA?.map((props: CallToAction, idx: number) => (
              <Button key={idx} {...props} />
            ))}
          </div>
        )}
        {!!urlTitle && !!url && (
          <div className={cardStyles.urlCta}>
            <div>{urlTitle}</div>
            <div>
              <img src={LongArrowLeft} className={cardStyles.urlArrowLeft} alt="Arrow pointing left" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimpleCard;
